<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">Informazioni del Sollecito</h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input name="name"
                      :error="failed ? 'Il campo Nome è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Nome (obbligatorio)"
                      v-model="form.name"
                      addon-left-icon="nc-icon nc-badge">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="descrizione"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="descrizione"
                      :error="failed ? 'Il campo Descrizione è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Descrizione (opzionale)"
                      v-model="form.descrizione"
                      addon-left-icon="nc-icon nc-notes">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Checkbox} from 'src/components/index'

  extend("required", required);
  extend("email", email);

  export default {
    props: ['sollecito'],
    data() {
      return {
        form: {
          name: "",
          descrizione: "",
        }
      };
    },
    components: {
      Checkbox
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      }
    },
    mounted() {
      if (this.sollecito) {
        this.form.name = this.sollecito.name
        this.form.descrizione = this.sollecito.description
      }
    }
  }
</script>
<style>
</style>
