<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-8">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard shape="tab"
                       @on-complete="wizardComplete"
                       error-color="#FB404B"
                       color="#35495E"
                       :title="sollecito ? 'Modifica Sollecito' : 'Creazione Sollecito'"
                       :subtitle="sollecito ? 'Modifica il sollecito '+ sollecito.name : 'Crea un nuovo template di sollecito'">


            <tab-content title="Informazioni sollecito"
                         class="col-12"
                         :before-change="() => validateStep('firstStep')"
                         icon="nc-icon nc-paper-2">
              <first-step :sollecito="sollecito" ref="firstStep" @on-validated="onStepValidated"></first-step>
            </tab-content>

            <tab-content title="Impostazioni"
                         class="col-12"
                         :before-change="() => validateStep('secondStep')"
                         icon="nc-icon nc-settings-90">
              <second-step :sollecito="sollecito" ref="secondStep" @on-validated="onStepValidated"></second-step>
            </tab-content>
            <tab-content title="Elementi"
                         class="col-12"
                         :before-change="() => validateStep('thirdStep')"
                         icon="nc-icon nc-puzzle-10">
              <third-step :sollecito="sollecito" ref="thirdStep" @on-validated="onStepValidated"></third-step>
            </tab-content>
            <tab-content title="Ultimo step"
                         class="col-12"
                         icon="nc-icon nc-check-2">
              <b-overlay
                id="overlay-background"
                :show="!hasLoaded"
                fixed
                no-wrap
                variant="light"
                opacity="0.85"
                blur="5px"
              >
              </b-overlay>
              <div>
                <h2 class="text-center text-space">Fine!
                  <br>
                  <small>Clicca su "<b>Fine</b>" per creare un nuovo Sollecito</small>
                </h2>
              </div>

            </tab-content>

            <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Back</button>
            <button slot="next" class="btn btn-default btn-fill btn-wd btn-next">Next</button>
            <button slot="finish" class="btn btn-success btn-fill btn-wd">Fine</button>
          </form-wizard>
            <!-- footer -->
            <template v-if="sollecito && sollecito.tips.length > 0" :scope="{}">
              <div style="width: 90%; margin: auto; margin-bottom: 14px;" class="alert alert-danger" role="alert">
                Il Sollecito è associato a <strong>{{ sollecito.tips.length }}</strong> Programmazioni attualmente in corso. Le modifiche non avranno effetto su di esse.<br/>
                <i>Se però le Programmazioni sono ricorsive le modifiche verranno riportate sulle successive ripetizioni.</i>
              </div>
              
            </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import FirstStep from './Wizard/FirstStep.vue'
  import SecondStep from './Wizard/SecondStep.vue'
  import ThirdStep from './Wizard/ThirdStep.vue'
  import TemplateService from 'src/services/TemplateService'
  import Swal from 'sweetalert2'

  export default {
    props: ['sollecito'],
    data () {
      return {
        hasLoaded: true,
        error: null,
        wizardModel: {}
      }
    },
    components: {
      FormWizard,
      TabContent,
      FirstStep,
      SecondStep,
      ThirdStep,
    },
    mounted() {
      if (this.sollecito) {
        console.log('EDIT', this.sollecito)
      } else {
        console.log('CREATE')
      }
    },
    methods: {
      validateStep(ref) {
        return this.$refs[ref].validate()
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model}
      },
      async wizardComplete() {
        try {
          this.hasLoaded = false
          var firstData = this.$refs['firstStep'].form
          var secondData = this.$refs['secondStep'].form
          var thirdData = this.$refs['thirdStep']
          // delete thirdData.form
          console.log('TERZO', thirdData)

          var objToSend = {}
          objToSend.name = firstData.name
          objToSend.description = firstData.descrizione
          objToSend.subject = secondData.subject
          objToSend.settings = {
            count_msg: secondData.count_msg,
            iteration: "1:"+secondData.iteration+"g",
            message: secondData.message
          }
          objToSend.items = []
          
          thirdData.items.map(el => el.name).forEach((el,index) => {
            
            var item = {
              type: thirdData.$refs[el][0].form.type,
              label: thirdData.$refs[el][0].form.label,
              instructions: thirdData.$refs[el][0].form.instructions,
              mandatory: thirdData.$refs[el][0].form.mandatory,
              order:"" + index
            }
            if (thirdData.$refs[el][0].form.type === 'string') {
              objToSend.items.push(item)
            } else if (thirdData.$refs[el][0].form.type === 'file') {
              objToSend.items.push({
                ...item,
                mimetypes: thirdData.$refs[el][0].form.file.mimetypes,
                size: thirdData.$refs[el][0].form.file.size * 1000000
              })
            }
            
          })
          
          if (this.sollecito) {
            const template = await TemplateService.editTemplates(this.sollecito.id, objToSend)
            if (template) {
              console.log('tempate create', template)
            }
          } else {
            const template = await TemplateService.createTemplates(objToSend)
            if (template) {
              console.log('tempate create', template)
            }
          }
        } catch(err) {
          console.log('err', err)
          this.error = err
        } finally {
          this.hasLoaded = true
          Swal.fire({
            title: "Template creato correttamente",
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
          this.$router.push({
            path: '/admin/gestione-solleciti'
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  .vue-form-wizard .wizard-icon-circle.tab_shape {
    background-color: #9A9A9A !important;
    color: white;
  }
  .vue-form-wizard .wizard-tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .wizard-tab-container {
      display: block;
      animation: fadeIn 0.5s;
    }
  }
</style>
