import Api from '@/services/Api'

export default {
  getTemplates () {
    return Api().get('/model/template?include=true')
  },
  getTemplate (id) {
    return Api().get('/model/template/'+id)
  },
  createTemplates(data) {
    return Api().post('/model/template', data)
  },
  editTemplates(id, data) {
    return Api().patch('/model/template/'+id, data)
  },
  deleteTemplate(id) {
    return Api().delete('/model/template/'+id)
  },
}