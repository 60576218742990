<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="">
      <h5 class="text-center">File/Informazioni richiesti</h5>
      <div class="row text-center">
        <div class="col-xs-12 col-md-12">
          <span>
            Crea un una nuova card
            <button class="btn btn-social btn-round btn-twitter btn-outline" style="width: 37px;" @click="addClick()">
              <i class="nc-icon nc-simple-add"></i>
            </button>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-12" v-for="(item, indx) in items" :key="parseInt(item.name.split('_')[1])">
          <component v-bind:is="item.component" :id="item.name" :item="item.sollecito" :title="'Elemento '+Number(indx+1)" :ref="'item_'+item.name.split('_')[1]"></component>
        </div>
      </div>
    </form>
    <div style="background-color: red; color: white; font-weight: 800; padding: 4px; text-align: center; margin-bottom: 10px;" v-if="error">{{ error }}</div>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Checkbox} from 'src/components/index'
  import ItemCard from './ItemCard'
  import Vue from 'vue'

  extend("required", required);
  extend("email", email);

  export default {
    props: ['sollecito'],
    data() {
      return {
        form: {
          nome        : "",
          descrizione : "",
        },
        clicks   : 0,
        items    : [],
        list_key : 0,
        error    : null,
      };
    },
    components: {
      Checkbox,
      ItemCard
    },
    created() {
      this.$eventHub.$on('delete-card', (id) => {
        console.log(id)
        this.items.splice(this.items.findIndex((el => el.name === id)), 1)
      })
    },
    beforeDestroy() {
      this.error = null;
      this.$eventHub.$off('delete-card')
    },
    watch: {
      items_length: function ( new_value, old_value ) {
          this.error = null;
      },
    },
    computed:{
        items_length() { return this.items.length; },
    },
    methods: {
      validate() {
        this.error = null;
        if ( this.$refs.form.$children.length == 0 ) {
          this.error = 'Deve essere specificato almeno un elemento';
          return false;
        } else {
          for ( var i = 0; i < this.$refs.form.$children.length; i++ ) {
              var form  = this.$refs.form.$children[ i ].form;
              var title = this.$refs.form.$children[ i ].title;
              console.log( this.$refs.form.$children[ i ] );
              this.error = `i dati specificati nell'elemento ${ title } non sono validi`;
              if ( !form.label || !form.type ) { return false; }
          }
          this.error = null;
          return true;
//        return this.$refs.form.validate().then(res => {          
//          console.log( res );
//          this.$emit('on-validated', res)
//          return res
//        });
        }        
      },
      addClick() {
        let x = this.items.length
        this.items.push({
          name: 'item_'+x,
          component: ItemCard,
          sollecito: null
        })
      }
    },
    mounted() {
      console.log('Step 3', this.sollecito)
      if(this.sollecito) {
        for (let i = 0; i<this.sollecito.items.length; i++) {
          this.items.push({
            name: 'item_'+i,
            component: ItemCard,
            sollecito: this.sollecito.items[i]
          })
        }
      }
    }
  }
</script>
<style>
</style>
