<template>
  <ValidationObserver ref="form">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
    <form @submit.prevent="validate">
      <h5 class="text-center">Impostazioni Messaggi</h5>
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <ValidationProvider
            name="subject"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input name="subject"
                      :error="failed ? 'Il campo Oggetto è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Oggetto dell'email (obbligatorio)"
                      v-model="form.subject"
                      addon-left-icon="nc-icon nc-email-83">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <!-- <h4 class="title">Messaggio</h4> -->
        <div class="col-xs-12 col-md-12 row">
          <div v-for="pchl in placeholders" :key="pchl.id">
              <l-button style="margin-left: 15px; padding: 4px 8px;" wide round @click="addPlaceholder(pchl)">{{ pchl.description }}</l-button>&nbsp;
          </div>
        </div>
        <div class="col-xs-12 col-md-12" style="padding-left:15px;">
          <ValidationProvider
            name="message"
            rules="required"
            v-slot="{ failed, passed }"
          >
            <fg-input
              name="message"
              :error="failed ? 'Il campo Messaggio è obbligatorio': null"
              :hasSuccess="passed"
              addon-left-icon="nc-icon nc-align-center">
                <textarea v-model="form.message" id="textarea_message" class="form-control" placeholder="Messaggio (obbligatorio)" rows="9"></textarea>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <ValidationProvider
            name="countMsg"
            rules="positive"
            v-slot="{ failed, passed }"
          >
            <fg-input name="countMsg" label="Numero Messaggi" :error="failed ? 'Il campo Numero Messaggi è obbligatorio': null" :hasSuccess="passed">
              <el-input-number v-model="form.count_msg"
                               placeholder="Numero Messaggi (obbligatorio)"
                               name="countMsg">
              </el-input-number>
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-xs-12 col-md-6">
           <ValidationProvider
            name="iteration"
            rules="positive"
            v-slot="{ failed, passed }"
            
          >
            <fg-input name="iteration" label="Indica ogni quanti giorni vuoi inviare un messaggio" :error="failed ? 'Il campo Cadenza Messaggi è obbligatorio': null" :hasSuccess="passed">
              <el-input-number v-model="form.iteration"   
                               placeholder="Cadenza Messaggi (obbligatorio)"
                               name="iteration">
              </el-input-number>
            </fg-input>
          </ValidationProvider>
        </div>
        <!-- <div class="col-xs-12 col-md-6">
          <h4 class="title">Cadenza Messaggi</h4>
          <ValidationProvider
            name="dateTime"
            rules="required"
            v-slot="{ failed }"
          >
            <fg-input label="Data di inzio e fine sollecito"
                    :error="failed ? 'Il campo Cadenza è oggligatorio': null">
              <el-date-picker
                name="dateTime"
                v-model="form.dateTimePicker"
                type="datetimerange"
                placeholder="Cadenza"
                :picker-options="pickerOptions1">
              </el-date-picker>
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-xs-12 col-md-6">
          <h4 class="title">Orario di Invio</h4>
          <ValidationProvider
            name="schedule"
            rules="required"
            v-slot="{ failed }"
          >
            <fg-input label="Orario di invio sollecito"
                    :error="failed ? 'Il campo Orario è oggligatorio': null">
              <el-time-select
                name="schedule"
                v-model="form.schedule"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:59'
                }"
                placeholder="Orario">
              </el-time-select>
            </fg-input>
          </ValidationProvider>
        </div> -->
      </div>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import DefaultService from 'src/services/DefaultService'
  // import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option } from 'element-ui'
  // import {
  //   Progress as LProgress,
  //   Switch as LSwitch,
  //   Radio as LRadio,
  //   Checkbox as LCheckbox,
  //   FormGroupInput as FgInput
  // } from 'src/components/index'

  extend("required", required);
  extend("email", email);
  extend("regex", regex);
  extend('positive', value => {
    return value >= 1;
  });

  export default {
    props: ['sollecito'],
    components: {
      // FgInput,
      // [DatePicker.name]: DatePicker,
      // [TimeSelect.name]: TimeSelect,
      // [Slider.name]: Slider,
      // [Tag.name]: Tag,
      // [Input.name]: Input,
      // [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      // LSwitch,
      // LProgress,
      // LRadio,
      // LCheckbox
    },
    data () {
      return {
        form: {
          subject: '',
          message: '',
          count_msg: 1,
          iteration: 1
        },
        // pickerOptions1: {
        //   shortcuts: [{
        //     text: 'Today',
        //     onClick (picker) {
        //       picker.$emit('pick', new Date())
        //     }
        //   },
        //   {
        //     text: 'Yesterday',
        //     onClick (picker) {
        //       const date = new Date()
        //       date.setTime(date.getTime() - 3600 * 1000 * 24)
        //       picker.$emit('pick', date)
        //     }
        //   },
        //   {
        //     text: 'A week ago',
        //     onClick (picker) {
        //       const date = new Date()
        //       date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
        //       picker.$emit('pick', date)
        //     }
        //   }]
        // },
        placeholders: [],
        hasLoaded: false,
        error: false
      }
    },
    async mounted() {
      try {
        const placeholders = await DefaultService.getPlaceholders()
        if (placeholders) {
          this.placeholders = placeholders.data.map(el => {
            var obj = {}
            obj.id = el.id
            obj.label = el.label,
            obj.description = el.description
            return obj
          })
        }
      } catch(err) {
        console.log('err', err)
        this.error = 'Errore durante la get di placeholders'
      } finally {
        this.hasLoaded = true
        if (this.sollecito) {
          this.form.subject = this.sollecito.subject
          this.form.message = this.sollecito.settings.message
          this.form.count_msg = parseInt(this.sollecito.settings.count_msg)
          this.form.iteration = parseInt(this.sollecito.settings.iteration.split(":")[1].split("g")[0])
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      },
      addPlaceholder(placeholder) {
        let pchl = this.placeholders.filter(el => el.id === placeholder.id)[0]
        this.form.message += '[pchl='+pchl.label+']'
        document.getElementById("textarea_message").focus();
      }
    }
  }
</script>
<style>
</style>
