var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('b-overlay',{attrs:{"id":"overlay-background","show":!_vm.hasLoaded,"fixed":"","no-wrap":"","variant":"light","opacity":"0.85","blur":"5px"}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Impostazioni Messaggi")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12"},[_c('ValidationProvider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"subject","error":failed ? 'Il campo Oggetto è obbligatorio': null,"hasSuccess":passed,"placeholder":"Oggetto dell'email (obbligatorio)","addon-left-icon":"nc-icon nc-email-83"},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 row"},_vm._l((_vm.placeholders),function(pchl){return _c('div',{key:pchl.id},[_c('l-button',{staticStyle:{"margin-left":"15px","padding":"4px 8px"},attrs:{"wide":"","round":""},on:{"click":function($event){return _vm.addPlaceholder(pchl)}}},[_vm._v(_vm._s(pchl.description))]),_vm._v(" \n        ")],1)}),0),_c('div',{staticClass:"col-xs-12 col-md-12",staticStyle:{"padding-left":"15px"}},[_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('fg-input',{attrs:{"name":"message","error":failed ? 'Il campo Messaggio è obbligatorio': null,"hasSuccess":passed,"addon-left-icon":"nc-icon nc-align-center"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control",attrs:{"id":"textarea_message","placeholder":"Messaggio (obbligatorio)","rows":"9"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}})])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('ValidationProvider',{attrs:{"name":"countMsg","rules":"positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('fg-input',{attrs:{"name":"countMsg","label":"Numero Messaggi","error":failed ? 'Il campo Numero Messaggi è obbligatorio': null,"hasSuccess":passed}},[_c('el-input-number',{attrs:{"placeholder":"Numero Messaggi (obbligatorio)","name":"countMsg"},model:{value:(_vm.form.count_msg),callback:function ($$v) {_vm.$set(_vm.form, "count_msg", $$v)},expression:"form.count_msg"}})],1)]}}])})],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('ValidationProvider',{attrs:{"name":"iteration","rules":"positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('fg-input',{attrs:{"name":"iteration","label":"Indica ogni quanti giorni vuoi inviare un messaggio","error":failed ? 'Il campo Cadenza Messaggi è obbligatorio': null,"hasSuccess":passed}},[_c('el-input-number',{attrs:{"placeholder":"Cadenza Messaggi (obbligatorio)","name":"iteration"},model:{value:(_vm.form.iteration),callback:function ($$v) {_vm.$set(_vm.form, "iteration", $$v)},expression:"form.iteration"}})],1)]}}])})],1)]),(_vm.error)?_c('div',{staticClass:"row custom_error"},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.error)+" ")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }