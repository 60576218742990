<template>
  <card :title="title" sub-title="Item del template">
    <span class="close" @click="deleteCard()">&times;</span>
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <fg-input label="Etichetta" type="text" placeholder="Inserisci un'etichetta" v-model="form.label">
        </fg-input>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <!-- <fg-input label="Istruzioni" type="text"> -->
          <textarea v-model="form.instructions" placeholder="Descrivi le istruzioni" rows="3" style="width:100%;">
          </textarea>
        <!-- </fg-input> -->
      </div>
      <div class="col-xs-12 col-md-6">
        <fg-input label="Tipologia">
          <el-select name="type" 
                    class="select-default"
                    size="large"
                    v-model="form.type"
                    placeholder="Seleziona una tipologia">
            <el-option v-for="type in types"
                      class="select-default"
                      :value="type.value"
                      :label="type.label"
                      :key="type.value">
            </el-option>
          </el-select>
          </fg-input>
      </div>
    </div>
    <!-- FILE -->
    <!-- <div class="row" v-if="check_type === 'file'"> -->
    <div class="row" v-if="false">
      <div class="col-xs-12 col-md-6">
        <fg-input label="Tipi dei file accettati">
            <el-select multiple class="select-default"
                      size="large"
                      v-model="form.file.mimetypes"
                      placeholder="Seleziona un gruppo">
              <el-option v-for="mt in mimetypes"
                        class="select-default"
                        :value="mt.value"
                        :label="mt.label"
                        :key="mt.value">
              </el-option>
            </el-select>
          </fg-input>
      </div>
      <div class="col-xs-12 col-md-6">
        <fg-input name="fileSize" label="Dimensione File, espresso in MegaBytes">
          <el-input-number v-model="form.file.size"
                           placeholder="Dimensione File"
                           name="fileSize">
          </el-input-number>
        </fg-input>
      </div>
    </div>

    <!-- STRINGA -->
    <!-- Ci sarà una validazione -->

    <div class="form-group">
      <fg-input label="">
        <div>
          <l-checkbox v-model="form.mandatory" :inline="true">Obbligatorio?</l-checkbox>
        </div>
      </fg-input>
    </div>
  </card>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import {
    Checkbox as LCheckbox,
    FormGroupInput as FgInput
  } from 'src/components/index'
  export default {
    props: ['title', 'id', 'item'],
    data() {
      return {
        form: {
          label: '',
          instructions: '',
          type: null,
          mandatory: false,
          file: {
            size: 100,
            mimetypes: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'application/msword']
          }
        },
        mimetypes: [
          {value: 'image/jpg', label: 'JPG'},
          {value: 'image/jpeg', label: 'JPEG'},
          {value: 'image/png', label: 'PNG'},
          {value: 'application/pdf', label: 'PDF'},
          {value: 'text/plain', label: 'TXT'},
          {value: 'application/msword', label: 'DOC'}
        ],
        types: [
          { value: null, label: 'Seleziona una tipologia' },
          { value: 'string', label: 'Stringa'},
          { value: 'file', label: 'File'}
        ]
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      LCheckbox
    },
    computed: {
      check_type: function() {
        return this.form.type
      }
    },
    methods: {
      deleteCard() {
        this.$eventHub.$emit('delete-card', this.id)
      }
    },
    mounted() {
      console.log('Sollecito in Itemcard', this.item)
      if (this.item) {
        this.form.label = this.item.label
        this.form.instructions = this.item.instructions
        this.form.mandatory = this.item.mandatory
        this.form.type = this.item.type
        if (this.item.type === 'file') {
          this.form.file.size = this.item.max_size
          this.form.file.mimetypes = this.item.mimetypes || this.item.mimetype
        }
      }
    }
  }
</script>

<style scoped>
  .close {
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 100;
      background-color: #FFF;
      padding: 5px 2px 2px;
      color: red;
      font-weight: bold;
      cursor: pointer;
      opacity: .2;
      text-align: center;
      font-size: 22px;
      line-height: 10px;
      border-radius: 50%;
  }
  .close:hover {
      opacity: 1;
  }
</style>